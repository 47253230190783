var render = function render(){
  var _vm$order, _vm$order2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.template, {
    tag: "component"
  }, [_vm.ready && _vm.order ? _c('v-responsive', {
    attrs: {
      "width": "100%"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "0",
      "tile": "",
      "max-width": "100%"
    }
  }, [_c('v-card-title', {
    staticClass: "headline d-flex d-sm-block justify-center"
  }, [_c('span', {
    staticClass: "py-4 pl-sm-4"
  }, [_c('b', [_vm._v("주문결과")])])])], 1), _c('v-card', {
    attrs: {
      "elevation": "0",
      "tile": "",
      "max-width": "100%"
    }
  }, [_c('v-divider'), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "subtitle-1"
  }, [_vm._v("[" + _vm._s(_vm.resCode) + "] " + _vm._s(_vm.resMessage))]), _c('div', {
    staticClass: "body-1"
  }, [_vm.resCode == '0000' || _vm.resCode == '3001' || _vm.resCode == 'EC0000' || _vm.chargeState === '승인' ? _c('div', [_vm._v("결제가 완료되었습니다.")]) : _c('div', [_vm._v("결제가 완료되지 않았습니다")])])]), _vm._l((_vm$order = _vm.order) === null || _vm$order === void 0 ? void 0 : _vm$order.purchases, function (item, index) {
    return [_c('v-divider', {
      key: index
    }), _c('pickup-item', {
      key: item._id,
      attrs: {
        "cart": item
      }
    })];
  }), _vm._l((_vm$order2 = _vm.order) === null || _vm$order2 === void 0 ? void 0 : _vm$order2.pickups, function (item, index) {
    return [_c('v-divider', {
      key: index
    }), _c('pickup-item', {
      key: item._id,
      attrs: {
        "cart": item
      }
    })];
  }), _c('v-divider'), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-layout', [_c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_vm._v("총 상품가격")]), _c('v-spacer'), _c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_vm._v(_vm._s(_vm.order.saleAmount.format()) + " 원")])], 1)], 1), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-layout', [_c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_vm._v("구매수수료")]), _c('v-spacer'), _c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_vm._v(_vm._s(_vm.order.commissionAmount.format()) + " 원")])], 1)], 1), _c('v-card-text', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_vm._v("총 주문 금액")]), _c('v-spacer'), _c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_vm._v(_vm._s(_vm.order.totalAmount.format()) + " 원")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_c('b', [_vm._v("결제 금액")])]), _c('v-spacer'), _c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.order.paymentAmount.format()) + " 원")])])], 1)], 1), _c('v-divider')], 2), _c('v-card', {
    staticClass: "mt-8",
    attrs: {
      "outlined": "",
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("구매자 정보")]), _c('v-divider'), _c('v-card-text', [_c('v-layout', {
    staticClass: "mx-n2 mt-n4",
    attrs: {
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "mt-4 px-2",
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('div', [_c('div', {
    staticClass: "caption"
  }, [_vm._v("이름")]), _c('div', {
    staticClass: "body-2 black--text"
  }, [_vm._v(_vm._s(_vm.order.buyer.name || "-"))])])]), _c('v-flex', {
    staticClass: "mt-4 px-2",
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('div', [_c('div', {
    staticClass: "caption"
  }, [_vm._v("연락처")]), _c('div', {
    staticClass: "body-2 black--text"
  }, [_vm._v(_vm._s(_vm.order.buyer.phone || "-"))])])]), _c('v-flex', {
    staticClass: "mt-4 px-2",
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('div', [_c('div', {
    staticClass: "caption"
  }, [_vm._v("이메일")]), _c('div', {
    staticClass: "body-2 black--text"
  }, [_vm._v(_vm._s(_vm.order.buyer.email || "-"))])])])], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-8",
    attrs: {
      "tile": "",
      "outlined": "",
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("수취인")]), _c('v-divider'), _c('v-card-text', [_c('v-layout', {
    staticClass: "mx-n2 mt-n4",
    attrs: {
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "mt-4 px-2",
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('div', [_c('div', {
    staticClass: "caption"
  }, [_vm._v("이름")]), _c('div', {
    staticClass: "body-2 black--text"
  }, [_vm._v(_vm._s(_vm.order.receiver.name || "-"))])])]), _c('v-flex', {
    staticClass: "mt-4 px-2",
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('div', [_c('div', {
    staticClass: "caption"
  }, [_vm._v("연락처")]), _c('div', {
    staticClass: "body-2 black--text"
  }, [_vm._v(_vm._s(_vm.order.receiver.phone || "-"))])])]), _c('v-flex', {
    staticClass: "mt-4 px-2",
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('div', [_c('div', {
    staticClass: "caption"
  }, [_vm._v("이메일")]), _c('div', {
    staticClass: "body-2 black--text"
  }, [_vm._v(_vm._s(_vm.order.receiver.email || "-"))])])])], 1)], 1)], 1), _c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4",
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("요청사항")]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.order.demand || "-") + " ")])], 1), _c('v-layout', {
    staticClass: "mt-8",
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "tile": "",
      "x-large": "",
      "outlined": "",
      "elevation": "0",
      "to": "/"
    }
  }, [_vm._v("홈으로가기")]), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mx-1",
    attrs: {
      "tile": "",
      "x-large": "",
      "color": "primary",
      "elevation": "0",
      "to": "/mypage/shop/purchases"
    }
  }, [_vm._v("주문배송확인")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }