<template>
    <component :is="template">
        <v-responsive v-if="ready && order" width="100%">
            <v-card elevation="0" tile max-width="100%">
                <v-card-title class="headline d-flex d-sm-block justify-center">
                    <span class="py-4 pl-sm-4"><b>주문결과</b></span>
                </v-card-title>
            </v-card>

            <v-card elevation="0" tile max-width="100%">
                <v-divider/>
                <v-card-text class="text-center">
                    <div class="subtitle-1">[{{ resCode }}] {{ resMessage }}</div>
                    <div class="body-1">
                        <div v-if="resCode == '0000' || resCode == '3001' || resCode == 'EC0000'|| chargeState === '승인'">결제가 완료되었습니다.</div>
                        <div v-else>결제가 완료되지 않았습니다</div>
                    </div>
                </v-card-text>
                
                <template v-for="item, index in order?.purchases">
                    <v-divider :key="index"/>
                    <pickup-item :key="item._id" :cart="item" />
                </template>

                <template v-for="item, index in order?.pickups">
                    <v-divider :key="index"/>
                    <pickup-item :key="item._id" :cart="item" />
                </template>

                <v-divider/>

                <v-card-text class="pb-0">
                    <v-layout>
                        <v-flex grow-shrink-0>총 상품가격</v-flex>
                        <v-spacer/>
                        <v-flex grow-shrink-0>{{ order.saleAmount.format() }} 원</v-flex>
                    </v-layout>
                </v-card-text>

                <v-card-text class="pb-0">
                    <v-layout>
                        <v-flex grow-shrink-0>구매수수료</v-flex>
                        <v-spacer/>
                        <v-flex grow-shrink-0>{{ order.commissionAmount.format() }} 원</v-flex>
                    </v-layout>
                </v-card-text>

                <v-card-text>
                    <v-layout>
                        <v-flex grow-shrink-0>총 주문 금액</v-flex>
                        <v-spacer/>
                        <v-flex grow-shrink-0>{{ order.totalAmount.format() }} 원</v-flex>
                    </v-layout>
                </v-card-text>

                <v-divider />

                <v-card-text>
                    <v-layout>
                        <v-flex grow-shrink-0><b>결제 금액</b></v-flex>
                        <v-spacer/>
                        <v-flex grow-shrink-0><b>{{ order.paymentAmount.format() }} 원</b></v-flex>
                    </v-layout>
                </v-card-text>

                <v-divider />
            </v-card>

            <v-card outlined tile elevation="0" class="mt-8">
                <v-card-title class="subtitle-2">구매자 정보</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-layout wrap class="mx-n2 mt-n4">
                        <v-flex xs12 md3 class="mt-4 px-2"><div><div class="caption">이름</div><div class="body-2 black--text">{{ order.buyer.name || "-" }}</div></div></v-flex>
                        <v-flex xs12 md3 class="mt-4 px-2"><div><div class="caption">연락처</div><div class="body-2 black--text">{{ order.buyer.phone || "-" }}</div></div></v-flex>
                        <v-flex xs12 md3 class="mt-4 px-2"><div><div class="caption">이메일</div><div class="body-2 black--text">{{ order.buyer.email || "-" }}</div></div></v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>

            <v-card tile outlined elevation="0" class="mt-8">
                <v-card-title class="subtitle-2">수취인</v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-layout wrap class="mx-n2 mt-n4">
                        <v-flex xs12 md3 class="mt-4 px-2"><div><div class="caption">이름</div><div class="body-2 black--text">{{ order.receiver.name || "-" }}</div></div></v-flex>
                        <v-flex xs12 md3 class="mt-4 px-2"><div><div class="caption">연락처</div><div class="body-2 black--text">{{ order.receiver.phone || "-" }}</div></div></v-flex>
                        <v-flex xs12 md3 class="mt-4 px-2"><div><div class="caption">이메일</div><div class="body-2 black--text">{{ order.receiver.email || "-" }}</div></div></v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
<!-- 
            <v-card v-show="false" tile elevation="0" class="mt-4">
                <v-card-title class="subtitle-2">배송지</v-card-title>
                <v-card-text>
                    [{{ order.shippingAddress.postcode }}] {{ order.shippingAddress.address1 }} {{ order.shippingAddress.address2 }}
                </v-card-text>
            </v-card> -->

            <v-card v-show="false" tile elevation="0" class="mt-4">
                <v-card-title class="subtitle-2">요청사항</v-card-title>
                <v-card-text>
                    {{ order.demand || "-" }}
                </v-card-text>
            </v-card>

            <v-layout class="mt-8" justify-center>
                <v-btn tile x-large outlined elevation="0" class="mx-1" to="/">홈으로가기</v-btn>
                <v-btn v-show="false" tile x-large color="primary" elevation="0" class="mx-1" to="/mypage/shop/purchases">주문배송확인</v-btn>
            </v-layout>
            
        </v-responsive>
    </component>
</template>
<script>
import api from "@/api";
import { mdiReceipt, mdiBookOpenPageVariant  } from "@mdi/js";
import PickupItem from "@/components/client/gshop/order/pickup-item.vue";
export default {
    components: {
        PickupItem,
    },
    data(){
        return {
            mdiReceipt,
            mdiBookOpenPageVariant,

            ready: false,

            resCode: this.$route.query.resCode,
            resMessage: this.$route.query.resMessage,

            orderNo: this.$route.query.orderNo,
            order: null,

            chargeState: this.$route.query.chargeState,
        }
    },
    async mounted(){
        await this.init();
    },
    methods: {
        async init(){
            // 상품페이지 & 장바구니에서 픽업된 상품 로드
            let { order } = await api.v1.me.gshop.orders.get({ orderNo: this.orderNo });

            this.order = order;
            this.ready = true;

        }
    },
    computed: {
        template(){ return () => import(`@/templates/${this.$theme.pages.gshop}`); },
    },
}
</script>

<style scoped>
.row + .row {
    margin-top: 0px;
}
.v-number-field >>> input {
    text-align: right !important;
}
.v-input >>> .v-input__slot:before {
    border: 0 !important;
}
</style>